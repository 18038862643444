import { atom } from "jotai";

export const customerIdAtom = atom(0);
export const tokenAtom = atom({ accessToken: "", refreshToken: "" });

const fromDate0 = new Date();
fromDate0.setDate(fromDate0.getDate() - 365);

export const selectedPortfolioIdAtom = atom<number>(0);
export const selectedCurrencyAtom = atom<string>("USD");
export const selectedDateAtom = atom<Date>(new Date());
export const selectedFromDateAtom = atom<Date>(fromDate0);
export const selectedToDateAtom = atom<Date>(new Date());
export const clickedSymbolAtom = atom<string>("");
export const clickedPortfolioIdAtom = atom<number>(0);
export const useCanadianCostBasisAtom = atom<boolean>(false);
export const useSbiTtRatesAtom = atom<boolean>(false);

