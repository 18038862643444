import React, { useState } from "react";
import { Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import "chart.js/auto";
import axios from "axios";
import { useAtom } from "jotai";
import { tokenAtom } from "../state/atoms";
import DatePicker from "react-datepicker";

interface Stock {
  name: string;
  ticker: string;
  enabled: boolean;
  newline?: boolean;
  color: string;
  line_width?: number;
}

const stockList: Stock[] = [
  { name: "S&P 500", ticker: "S&P500", enabled: true, color: "#69AF46", line_width: 6 },
  { name: "NASDAQ", ticker: "NASDAQ", enabled: true, color: "#AF8C00", line_width: 6 },
  { name: "Russell 2000", ticker: "Russell2000", enabled: false, color: "#69AF00", line_width: 6 },
  { name: "Dow Jones", ticker: "DJIA", enabled: false, color: "#AF8C23", line_width: 6 },
  { name: "S&P TSX (Canada)", ticker: "S&PTSX", enabled: true, newline: true, color: "#AF4669", line_width: 6 },
  { name: "Nifty 50 (India)", ticker: "NIFTY50", enabled: true, color: "#46238C", line_width: 6 },
  { name: "Technology (XLK)", ticker: "XLK", enabled: false, newline: true, color: "#460046" },
  { name: "Healthcare (XLV)", ticker: "XLV", enabled: false, color: "#468C46" },
  { name: "Financials (XLF)", ticker: "XLF", enabled: false, color: "#AFAF23" },
  { name: "Consumer Discretionary (XLY)", ticker: "XLY", enabled: false, color: "#8C468C" },
  { name: "Consumer Staples (XLP)", ticker: "XLP", enabled: false, color: "#000069" },
  { name: "Energy (XLE)", ticker: "XLE", enabled: false, color: "#23008C" },
  { name: "Industrials (XLI)", ticker: "XLI", enabled: false, color: "#236946" },
  { name: "Materials (XLB)", ticker: "XLB", enabled: false, color: "#008C23" },
  { name: "Utilities (XLU)", ticker: "XLU", enabled: false, color: "#468C69" },
  { name: "Real Estate (XLRE)", ticker: "XLRE", enabled: false, color: "#8C6946" },
  { name: "Communication Services (XLC)", ticker: "XLC", enabled: false, color: "#46AFAF" },
  { name: "SCHG", ticker: "SCHG", enabled: false, color: "#696923" },
  { name: "SMH", ticker: "SMH", enabled: false, color: "#8C8C69" },
  { name: "TQQQ", ticker: "TQQQ", enabled: false, color: "#8C00AF" },
];

const SectorChart: React.FC = () => {
  const [selectedStocks, setSelectedStocks] = useState<string[]>([]);
  const [maPeriod, setMaPeriod] = useState<number>(14);
  const [diff, setDiff] = useState<number>(0);
  const [useTechicals, setUseTechicals] = useState<boolean>(true);
  const [chartData, setChartData] = useState<any>(null);
  const [token] = useAtom(tokenAtom);
  const [fromDt, setFromDt] = useState<Date>(new Date(new Date().setFullYear(new Date().getFullYear() - 5)));
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  const handleStockChange = (ticker: string) => {
    setSelectedStocks((prev) => (prev.includes(ticker) ? prev.filter((s) => s !== ticker) : [...prev, ticker]));
  };

  // Add the stocks with enabled=true in selected stocks
  React.useEffect(() => {
    setSelectedStocks(stockList.filter((stock) => stock.enabled).map((stock) => stock.ticker));
  }, []);

  const handleChartClick = async () => {
    if (selectedStocks.length === 0) {
      alert("Please select at least one stock");
      return;
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${token.accessToken}` },
        params: {
          symbols_csv: selectedStocks.join(","),
          ma_prd: maPeriod,
          diff_prd: diff,
          use_technicals: useTechicals,
          from_dt: fromDt.toISOString().split("T")[0],
        },
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/sector_charts_analytics_data`, config);

      const data = response.data;
      console.log("Data:", data);
      const datasets = selectedStocks.map((symbol: string) => {
        return {
          label: symbol,
          data: data[symbol]?.map((entry: { dt: string; val: number }) => ({
            x: entry.dt,
            y: entry.val,
          })),
          borderColor: getColorBySymbol(symbol),
          fill: false,
          pointRadius: 0,
          borderWidth: getLineWidth(symbol),
        };
      });

      setChartData({
        datasets,
      });
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getColorBySymbol = (symbol: string) => {
    const stock = stockList.find((s) => s.ticker === symbol);
    return stock ? stock.color : getRandomColor();
  };

  const getLineWidth = (symbol: string) => {
    const stock = stockList.find((s) => s.ticker === symbol);
    return stock ? stock.line_width : 1;
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {/* Collapsible Sidebar */}
      <div
        style={{
          width: isSidebarOpen ? "15%" : "3%",
          transition: "width 0.3s",
          backgroundColor: "#f4f4f4",
          padding: isSidebarOpen ? "10px" : "0",
          boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
        }}
      >
        {isSidebarOpen && (
          <>
            <h3>Select Indices/Sectors</h3>
            <div>
              {stockList.map((stock) => (
                <>
                  <span key={stock.ticker}>
                    <label>
                      <input type="checkbox" checked={selectedStocks.includes(stock.ticker)} onChange={() => handleStockChange(stock.ticker)} />
                      {/* Set stock name, in bold font if line_width element is avialble and value > 1 */}
                      <label style={{ fontWeight: stock.line_width && stock.line_width > 1 ? "bold" : "normal" }}>{stock.name}</label>
                    </label>
                    <br />
                  </span>
                </>
              ))}
            </div>

            <div>
              <label>
                Use Technicals:
                <input type="checkbox" checked={useTechicals} onChange={(e) => setUseTechicals(e.target.checked)} />
              </label>
              <label>
                MA :
                <input type="number" disabled={!useTechicals} value={maPeriod} onChange={(e) => setMaPeriod(parseInt(e.target.value))} />
              </label>
              <label>
                Diff:
                <input type="number" disabled={!useTechicals} value={diff} onChange={(e) => setDiff(parseInt(e.target.value))} />
              </label>
              <label>
                From:
                <DatePicker className="form-control" selected={fromDt} onChange={(date: Date) => setFromDt(date)} />
              </label>
              <button className="btn btn-primary" id="btn-chart" onClick={handleChartClick}>
                Chart
              </button>
            </div>
          </>
        )}
        <div style={{ alignItems: "right", textAlign: "right" }}>
          <button className="btn btn-secondary" onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ marginBottom: "10px" }}>
            {isSidebarOpen ? "<<" : ">>"}
          </button>
        </div>
      </div>

      <div style={{ width: isSidebarOpen ? "85%" : "97%", transition: "width 0.3s", padding: "20px" }}>
        {chartData && (
          <Chart
            type="line"
            data={chartData}
            options={{
              scales: {
                x: {
                  type: "time",
                  title: {
                    display: true,
                    text: "Date",
                  },
                  time: {
                    unit: "day",
                    tooltipFormat: "d MMM yy",
                    displayFormats: {
                      day: "d MMM yy",
                    },
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "% Change",
                  },
                  beginAtZero: false,
                },
              },
              plugins: {
                annotation: {
                  annotations: {
                    zeroLine: {
                      type: "line",
                      yMin: 0,
                      yMax: 0,
                      borderColor: "red",
                      borderWidth: 2,
                      label: {
                        content: "Zero Line",
                        position: "center",
                      },
                    },
                  },
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SectorChart;
