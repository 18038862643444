import React, { useEffect, useState } from "react";
import axios, { Axios, ResponseType } from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAtom } from "jotai";
import { customerIdAtom, tokenAtom, selectedPortfolioIdAtom, selectedDateAtom, selectedCurrencyAtom } from "../state/atoms";
import "../styles/Portfolio.css";
import LineChart, { DataPoint } from "./LineChart";

interface PortfolioOption {
  portfolio_id: number;
  portfolio_name: string;
}

const ChartsTab: React.FC = () => {
  const [customerId] = useAtom(customerIdAtom);
  const [token] = useAtom(tokenAtom);
  const [selectedPortfolioId, setSelectedPortfolioId] = useAtom(selectedPortfolioIdAtom);
  const [date, setDate] = useAtom(selectedDateAtom);
  const [currency, setCurrency] = useAtom(selectedCurrencyAtom);
  const [portfolios, setPortfolios] = useState<PortfolioOption[]>([]);
  const [portfolioValueHistory, setPortfolioValueHistory] = useState<DataPoint[]>([]);
  const currentYear: number = new Date().getFullYear();
  const [year, setYear] = useState<number>(currentYear);

  useEffect(() => {
    const fetchPortfolios = async () => {
      const config = { headers: { Authorization: `Bearer ${token.accessToken}` } };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolios`, config);
      setPortfolios([{ portfolio_id: 0, portfolio_name: "All" }, ...response.data]);
    };

    if (token) fetchPortfolios();
  }, [token, customerId]);

  useEffect(() => {
    const fetchPortfolioValueHistory = async () => {
      const config = {
        headers: { Authorization: `Bearer ${token.accessToken}` },
        params: {
          reporting_currency: currency,
          dt: date.toISOString().split("T")[0],
          num_days: 365 * 2,
        },
      };
      const url = `${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolio/${selectedPortfolioId}/portfolio_value_history`;
      const response = await axios.get(url, config);
      // Convert response data to DataPoint array
      const arr1: DataPoint[] = response.data.map((entry: any) => {
        return {
          dt: new Date(entry.dt),
          cost_basis: entry.total_cost,
          closing_value: entry.closing_val,
          realized_pnl: entry.realized_pnl,
          dividend: entry.dividend,
        };
      });

      setPortfolioValueHistory(arr1);
    };

    if (token && selectedPortfolioId !== undefined) fetchPortfolioValueHistory();
  }, [selectedPortfolioId, date, currency, token, customerId]);

  const runTaxExcelReport = async () => {
    // Disable the btn-report-dwld button until the rports downloads
    const btnReportDwld = document.getElementById("btn-report-dwld");
    if (btnReportDwld) {
      btnReportDwld.setAttribute("disabled", "true");
    }

    // Make spinner visible
    const spinner = document.getElementById("spinner");
    if (spinner) {
      spinner.style.display = "block";
    }

    const taxYear = year;
    const config = {
      responseType: "blob" as ResponseType,
      headers: { Authorization: `Bearer ${token.accessToken}` },
      params: {
        year: taxYear,
      },
    };
    const url = `${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolio/${selectedPortfolioId}/tax_returns_excel`;
    const response = await axios.get(url, config);
    const blob = new Blob([await response.data]);
    const url2 = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url2;
    link.setAttribute("download", `TaxReturnsSheet-${taxYear}.xlsx`);
    document.body.appendChild(link);
    link.click();
    if (btnReportDwld) {
      btnReportDwld.removeAttribute("disabled");
    }
    if (spinner) {
      spinner.style.display = "none";
    }
    // link.remove();
    // window.URL.revokeObjectURL(url2); // Clean up
  };

  const totalCost = portfolioValueHistory && portfolioValueHistory.length > 0 ? portfolioValueHistory[0].cost_basis : 0;
  const currentValue = portfolioValueHistory && portfolioValueHistory.length > 0 ? portfolioValueHistory[0].closing_value : 0;
  const unrealizedPnL = portfolioValueHistory && portfolioValueHistory.length > 0 ? currentValue - totalCost : 0;
  const realizedPnL = portfolioValueHistory && portfolioValueHistory.length > 0 ? portfolioValueHistory[0].realized_pnl : 0;
  const dividend = portfolioValueHistory && portfolioValueHistory.length > 0 ? portfolioValueHistory[0].dividend : 0;
  const totalGain = portfolioValueHistory && portfolioValueHistory.length > 0 ? unrealizedPnL + realizedPnL + dividend : 0;

  return (
    <div className="container">
      <div className="title-bar">
        <h3>Open Positions</h3>
      </div>
      <div className="filter-bar bg-secondary p-2">
        <div className="filter-item align-items-center col-lg-6">
          <label className="font-bold">Portfolio: </label>
          <select className="form-control col-lg-6 col-sm-12" value={selectedPortfolioId} onChange={(e) => setSelectedPortfolioId(Number(e.target.value))}>
            {portfolios.map((portfolio) => (
              <option key={portfolio.portfolio_id} value={portfolio.portfolio_id}>
                {portfolio.portfolio_name}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item align-items-center col-lg-2">
          <label>Date: </label>
          <DatePicker className="form-control" selected={date} onChange={(date: Date) => setDate(date)} />
        </div>
        <div className="filter-item align-items-center col-lg-2">
          <label>Currency: </label>
          <select className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="USD">USD</option>
            <option value="CAD">CAD</option>
            <option value="INR">INR</option>
          </select>
        </div>
      </div>

      {portfolioValueHistory && portfolioValueHistory.length > 0 && (
        <>
          <div className="summary-container" style={{ height: "1200px" }}>
            <LineChart data={portfolioValueHistory} />
          </div>
          <div className="summary-container">
            <div className="summary-box">
              <div className="summary-title">Total Cost</div>
              <div className="summary-number">{totalCost.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
            </div>
            <div className="summary-box">
              <div className="summary-title">Current Value</div>
              <div className="summary-number">{currentValue.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
            </div>
            <div className="summary-box">
              <div className="summary-title">Unrealized P&L</div>
              <div className={(unrealizedPnL || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
                {unrealizedPnL.toLocaleString(undefined, { style: "currency", currency: currency })}
              </div>
            </div>
            <div className="summary-box">
              <div className="summary-title">Realized P&L</div>
              <div className={(realizedPnL || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
                {realizedPnL.toLocaleString(undefined, { style: "currency", currency: currency })}
              </div>
            </div>
            <div className="summary-box">
              <div className="summary-title">Dividends</div>
              <div className={(dividend || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
                {dividend.toLocaleString(undefined, { style: "currency", currency: currency })}
              </div>
            </div>
            <div className="summary-box">
              <div className="summary-title">Total Gain</div>
              <div className={(totalGain || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
                {totalGain.toLocaleString(undefined, { style: "currency", currency: currency })}
              </div>
            </div>
          </div>

          <div className="summary-container">
            <div className="spinner-border text-primary" role="status" id="spinner" style={{ display: "none" }}>
              <span className="sr-only">Working</span>
            </div>
            <label>Download Tax Returns for Year: </label>
            <select className="form-control" value={year} onChange={(e) => setYear(Number(e.target.value))}>
              {Array.from({ length: currentYear - 2023 + 1 }, (_, i) => 2023 + i).map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
            <button className="btn btn-primary" id="btn-report-dwld" onClick={runTaxExcelReport}>
              Download Tax Returns
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ChartsTab;
