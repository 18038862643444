import React, { useEffect, useRef } from "react";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import "chartjs-adapter-date-fns";

export interface DataPoint {
  dt: string;
  cost_basis: number;
  closing_value: number;
  realized_pnl: number;
  dividend: number;
}

interface LineChartProps {
  data: DataPoint[];
}

Chart.register(...registerables);

const LineChart: React.FC<LineChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const ctx = chartRef.current?.getContext("2d");

    if (!ctx) return;

    const chartData: ChartData<"line"> = {
      labels: data.map((entry) => entry.dt),
      datasets: [
        {
          label: "Cost Basis",
          data: data.map((entry) => entry.cost_basis),
          borderColor: "black",
          borderWidth: 2, // Making the line thick
          fill: false,
          pointRadius: 0, // Disabling the points
        },
        {
          label: "Closing Value",
          data: data.map((entry) => entry.closing_value),
          borderColor: "blue",
          borderWidth: 1,
          fill: false,
          pointRadius: 0, // Disabling the points
        },
        {
          label: "+ Realized PnL",
          data: data.map((entry) => entry.closing_value + entry.realized_pnl),
          borderColor: "green",
          borderWidth: 1,
          fill: false,
          pointRadius: 0, // Disabling the points
        },
        {
          label: "+ Dividend",
          data: data.map((entry) => entry.closing_value + entry.realized_pnl + entry.dividend),
          borderColor: "red",
          borderWidth: 1,
          fill: false,
          pointRadius: 0, // Disabling the points
        },
      ],
    };

    const chartOptions: ChartOptions<"line"> = {
      scales: {
        x: {
          type: "time",
          time: {
            unit: "day",
            tooltipFormat: "d MMM yy",
            displayFormats: {
              day: "d MMM yy",
            },
          },
        },
        y: {
          beginAtZero: false,
        },
      },
      plugins: {
        legend: {
          display: true, // Disabling the legend
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    const chartInstance = new Chart(ctx, {
      type: "line",
      data: chartData,
      options: chartOptions,
    });

    return () => {
      chartInstance.destroy();
    };
  }, [data]);

  return (
    <div style={{ width: "50%" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default LineChart;
