// src/api/services.ts
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username: string, password: string) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'password');
  params.append('username', username);
  params.append('password', password);

  const response = await axios.post(`${API_URL}/v1/auth/login`, params);
  return response.data;
};

export const fetchPortfolios = async (accessToken: string) => {
  const response = await axios.get(`${API_URL}/v1/1/portfolios`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
};

// Add other API functions here...
