import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import ClipboardJS from "clipboard";
import "react-datepicker/dist/react-datepicker.css";
import { useAtom } from "jotai";
import {
  customerIdAtom,
  tokenAtom,
  selectedPortfolioIdAtom,
  selectedFromDateAtom,
  selectedToDateAtom,
  selectedCurrencyAtom,
  useSbiTtRatesAtom,
} from "../state/atoms";
import { Form } from "react-bootstrap";
import StackedBarChart, { DividendChartData } from "./StackedBarChart";

interface PortfolioOption {
  portfolio_id: number;
  portfolio_name: string;
}

interface Dividend {
  portfolio_id: string;
  portfolio_name: string;
  currency: string;
  dt: string;
  symbol?: string;
  earning_type: string;
  dividend: number;
  tax_witheld: number;
  exch_rate?: number;
  dividend_rc?: number;
  tax_witheld_rc?: number;
}

const Dividends: React.FC = () => {
  const [customerId] = useAtom(customerIdAtom);
  const [token] = useAtom(tokenAtom);
  const [selectedPortfolioId, setSelectedPortfolioId] = useAtom(selectedPortfolioIdAtom);
  const [fromDate, setFromDate] = useAtom(selectedFromDateAtom);
  const [toDate, setToDate] = useAtom(selectedToDateAtom);
  const [currency, setCurrency] = useAtom(selectedCurrencyAtom);
  const [useSbiTtRates, setUseSbiTtRates] = useAtom(useSbiTtRatesAtom);
  const [portfolios, setPortfolios] = useState<PortfolioOption[]>([]);
  const [dividends, setDividends] = useState<Dividend[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [showDividends, setShowDividends] = useState<boolean>(true);
  const [showInterests, setShowInterests] = useState<boolean>(true);
  const tableRef = useRef<HTMLTableElement>(null);
  const [chartData, setChartData] = useState<DividendChartData[]>([]);

  const handleSbiTtToggle = () => {
    setUseSbiTtRates(!useSbiTtRates);
  };

  const handleShowDividendsToggle = () => {
    setShowDividends(!showDividends);
  };

  const handleShowInterestsToggle = () => {
    setShowInterests(!showInterests);
  };

  useEffect(() => {
    const fetchPortfolios = async () => {
      const config = { headers: { Authorization: `Bearer ${token.accessToken}` } };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolios`, config);
      setPortfolios([{ portfolio_id: 0, portfolio_name: "All" }, ...response.data]);
    };

    if (token) fetchPortfolios();
  }, [token, customerId]);

  useEffect(() => {
    const fetchDividends = async () => {
      const config = {
        headers: { Authorization: `Bearer ${token.accessToken}` },
        params: {
          reporting_currency: currency,
          from_dt: fromDate.toISOString().split("T")[0],
          to_dt: toDate.toISOString().split("T")[0],
          use_sbi_tt_rates: useSbiTtRates,
        },
      };
      const url = `${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolio/${selectedPortfolioId}/dividends_and_interests_by_date`;
      const response = await axios.get(url, config);
      setDividends(response.data);
      setChartData([]);
      // convert data to array of DividendChartData
      const chartDataT: DividendChartData[] = response.data.map((dividend: Dividend) => ({
        dt: dividend.dt,
        symbol: dividend.symbol || "",
        dividend: dividend.dividend_rc,
      }));
      // Sort chartDataT by dt asecending, dividend amount descending
      chartDataT.sort((a, b) => {
        if (a.dt < b.dt) return -1;
        if (a.dt > b.dt) return 1;
        if (a.dividend > b.dividend) return -1;
        if (a.dividend < b.dividend) return 1;
        return 0;
      });
      setChartData(chartDataT);
    };

    if (token && selectedPortfolioId !== undefined) fetchDividends();
  }, [selectedPortfolioId, fromDate, toDate, currency, useSbiTtRates, token, customerId]);

  const handleCopyClick = () => {
    if (tableRef.current) {
      const clipboard = new ClipboardJS("#copyButton", {
        target: () => tableRef.current as HTMLElement,
      });

      clipboard.on("success", (e) => {
        console.log("Table copied successfully!");
        e.clearSelection();
      });

      clipboard.on("error", (e) => {
        console.error("Failed to copy table:", e);
      });

      // clipboard.onClick({ currentTarget: document.getElementById("copyButton") });
    }
  };

  const containesAnyWord = (text: string, phrase: string) => {
    if (phrase.trim() === "") return true;
    const negate = phrase.includes("!");
    if (negate) {
      phrase = phrase.replaceAll("!", "");
    }
    if (phrase.trim() === "") return true;
    const words: string[] = phrase.toLowerCase().replaceAll(",", " ").trim().split(" ");
    return negate
      ? words.every((word) => word.trim() !== "" && text.toLowerCase().startsWith(word) === false)
      : words.some((word) => word.trim() !== "" && text.toLowerCase().startsWith(word));
  };

  const filteredDividends = dividends.filter(
    (dividend) =>
      containesAnyWord(dividend.symbol || "", filterText) &&
      ((dividend.earning_type === "Dividend" && showDividends) || (dividend.earning_type === "Interest" && showInterests))
  );
  const total_dividend = filteredDividends.reduce((sum, position) => sum + (position.earning_type === "Dividend" ? position.dividend_rc || 0 : 0), 0);
  const total_dividend_tax_witheld = filteredDividends.reduce(
    (sum, position) => sum + (position.earning_type === "Dividend" ? position.tax_witheld_rc || 0 : 0),
    0
  );
  const total_interest = filteredDividends.reduce((sum, position) => sum + (position.earning_type === "Interest" ? position.dividend_rc || 0 : 0), 0);
  const total_interest_tax_witheld = filteredDividends.reduce(
    (sum, position) => sum + (position.earning_type === "Interest" ? position.tax_witheld_rc || 0 : 0),
    0
  );
  const grand_total = total_dividend - Math.abs(total_dividend_tax_witheld) + total_interest + Math.abs(total_interest_tax_witheld);

  return (
    <div className="container">
      <div className="title-bar">
        <h3>Dividends &amp; Interests</h3>
      </div>
      <div className="filter-bar bg-secondary p-2">
        <div className="filter-item align-items-center col-lg-4">
          <label className="font-bold">Portfolio:</label>
          <select className="form-control" value={selectedPortfolioId} onChange={(e) => setSelectedPortfolioId(Number(e.target.value))}>
            {portfolios.map((portfolio) => (
              <option key={portfolio.portfolio_id} value={portfolio.portfolio_id}>
                {portfolio.portfolio_name}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item align-items-center col-lg-4">
          <label>Date Range: </label>
          <DatePicker className="form-control" selected={fromDate} onChange={(date: Date) => setFromDate(date)} /> to{" "}
          <DatePicker className="form-control" selected={toDate} onChange={(date: Date) => setToDate(date)} />
        </div>
        <div className="filter-item align-items-center col-lg-4">
          <label>Currency: </label>
          <select className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="USD">USD</option>
            <option value="CAD">CAD</option>
            <option value="INR">INR</option>
          </select>
        </div>
      </div>
      <div className="filter-bar bg-secondary p-2">
        <Form.Check type="switch" id="sbi-tt-switch" label="SBI TT Rates" checked={useSbiTtRates} onChange={handleSbiTtToggle} />
        <Form.Check type="switch" id="sbi-tt-switch" label="Show Dividends" checked={showDividends} onChange={handleShowDividendsToggle} />
        <Form.Check type="switch" id="sbi-tt-switch" label="Show Interests" checked={showInterests} onChange={handleShowInterestsToggle} />
      </div>
      <div className="quick-filter-box bg-secondary p-2">
        <input type="text" className="form-control" value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Filter by symbol..." />
      </div>

      {chartData.length > 0 && (
        <div className="summary-container">
          <StackedBarChart data={chartData} />
        </div>
      )}
      {/* Summary Section */}
      <div className="summary-container">
        <div className="summary-box">
          <div className="summary-title">Dividends ({currency})</div>
          <div className="summary-number green-cell">{total_dividend.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Tax Witheld on Dividends ({currency})</div>
          <div className="summary-number red-cell">{total_dividend_tax_witheld.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Interest ({currency})</div>
          <div className="summary-number green-cell">{total_interest.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Tax Witheld on Interest ({currency})</div>
          <div className="summary-number red-cell">{total_interest_tax_witheld.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
        </div>
        <div className="summary-box">
          <div className="summary-title">
            <br />
            <span style={{ fontSize: "16pt" }}>
              <b>=</b>
            </span>
          </div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Total ({currency})</div>
          <div className="summary-number green-cell">{grand_total.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
        </div>
      </div>
      <div className="right-al">
        <button className="btn" id="copyButton" onClick={handleCopyClick}>
          📋 Copy
        </button>
      </div>
      <table className="table table-striped" ref={tableRef}>
        <thead>
          <tr>
            <th className="numeric-cell">#</th>
            {selectedPortfolioId === 0 ? <th>Portfolio</th> : null}
            <th>Currency</th>
            <th className="numeric-cell">Date</th>
            <th>Symbol</th>
            <th>Earning Type</th>
            <th className="numeric-cell">Amount</th>
            <th className="numeric-cell">Tax Witheld</th>
            <th className="numeric-cell">Exch Rate</th>
            <th className="numeric-cell">Amount ({currency})</th>
            <th className="numeric-cell">Tax Witheld ({currency})</th>
          </tr>
        </thead>
        <tbody>
          {filteredDividends.map((dividend, index) => (
            <tr key={index}>
              <td className="numeric-cell">{index + 1}</td>
              {selectedPortfolioId === 0 ? <td>{dividend.portfolio_name}</td> : null}
              <td>{dividend.currency}</td>
              <td className="numeric-cell">{dividend.dt}</td>
              <td>{dividend.symbol}</td>
              <td>{dividend.earning_type}</td>
              <td className="numeric-cell">{dividend.dividend.toLocaleString(undefined, { style: "currency", currency: dividend.currency })}</td>
              <td className="numeric-cell">{dividend.tax_witheld.toLocaleString(undefined, { style: "currency", currency: dividend.currency })}</td>
              <td className="numeric-cell">{dividend.exch_rate?.toFixed(6)}</td>
              <td className="numeric-cell">{dividend.dividend_rc?.toLocaleString(undefined, { style: "currency", currency: currency })}</td>
              <td className="numeric-cell">{dividend.tax_witheld_rc?.toLocaleString(undefined, { style: "currency", currency: currency })}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Dividends;
