import React, { useState } from "react";
import { Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import "chart.js/auto";
import axios from "axios";
import { useAtom } from "jotai";
import { tokenAtom } from "../state/atoms";
import DatePicker from "react-datepicker";

interface Stock {
  name: string;
  ticker: string;
  enabled: boolean;
  color: string;
  line_width?: number;
}

const stockList: Stock[] = [
  { name: "Federal Funds Rate", color: "#690F46", ticker: "FEDFUNDS", enabled: true },
  { name: "10-Year Treasury Yield", color: "#AF8C00", ticker: "GS10", enabled: true },
  { name: "Unemployment Rate", color: "#69AF00", ticker: "UNRATE", enabled: true },
  { name: "GDP Growth Rate", color: "#AF8C23", ticker: "A191RL1Q225SBEA", enabled: true },
  { name: "M2 Supply", color: "#AF4669", ticker: "M2SL", enabled: false },
  { name: "Consumer Price Index", color: "#46238C", ticker: "CPIAUCSL", enabled: false },
];

const EconomicCharts: React.FC = () => {
  const [selectedStocks, setSelectedStocks] = useState<string[]>([]);
  const [chartData, setChartData] = useState<any>(null);
  const [token] = useAtom(tokenAtom);
  const [fromDt, setFromDt] = useState<Date>(new Date(new Date().setFullYear(1950, 0, 1)));
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);

  const handleStockChange = (ticker: string) => {
    setSelectedStocks((prev) => (prev.includes(ticker) ? prev.filter((s) => s !== ticker) : [...prev, ticker]));
  };

  // Add the stocks with enabled=true in selected stocks
  React.useEffect(() => {
    setSelectedStocks(stockList.filter((stock) => stock.enabled).map((stock) => stock.ticker));
  }, []);

  const handleChartClick = async () => {
    if (selectedStocks.length === 0) {
      alert("Please select at least one stock");
      return;
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${token.accessToken}` },
        params: {
          symbols_csv: selectedStocks.join(","),
          from_dt: fromDt.toISOString().split("T")[0],
        },
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/economic_time_series_data`, config);

      const data = response.data;
      console.log("Data:", data);
      const datasets = selectedStocks.map((symbol: string) => {
        return {
          label: symbol,
          data: data[symbol]?.map((entry: { dt: string; val: number }) => ({
            x: entry.dt,
            y: entry.val,
          })),
          borderColor: getColorBySymbol(symbol),
          yAxisID: symbol === "M2SL" ? "y1" : "y",
          fill: false,
          pointRadius: 0,
          borderWidth: getLineWidth(symbol),
        };
      });

      setChartData({
        datasets,
      });
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getColorBySymbol = (symbol: string) => {
    const stock = stockList.find((s) => s.ticker === symbol);
    return stock ? stock.color : getRandomColor();
  };

  const getLineWidth = (symbol: string) => {
    const stock = stockList.find((s) => s.ticker === symbol);
    return stock ? stock.line_width : 1;
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {/* Collapsible Sidebar */}
      <div
        style={{
          width: isSidebarOpen ? "15%" : "3%",
          transition: "width 0.3s",
          backgroundColor: "#f4f4f4",
          padding: isSidebarOpen ? "10px" : "0",
          boxShadow: "2px 0 5px rgba(0,0,0,0.1)",
        }}
      >
        {isSidebarOpen && (
          <>
            <h3>Metrics</h3>
            <div>
              {stockList.map((stock) => (
                <>
                  <span key={stock.ticker}>
                    <label>
                      <input type="checkbox" checked={selectedStocks.includes(stock.ticker)} onChange={() => handleStockChange(stock.ticker)} />
                      {/* Set stock name, in bold font if line_width element is avialble and value > 1 */}
                      <label style={{ fontWeight: stock.line_width && stock.line_width > 1 ? "bold" : "normal" }}>{stock.name}</label>
                    </label>
                    <br />
                  </span>
                </>
              ))}
            </div>

            <div>
              {/* Dropdown of same elemtnts to use for secondary axis, with an option of "None" */}
              <label>
                Secondary Axis:
                <select>
                  <option value="None">None</option>
                  {stockList.map((stock) => (
                    <option key={stock.ticker} value={stock.ticker} onClick={() => handleStockChange(stock.ticker)}>
                      {stock.name}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            <div>
              <label>
                From:
                <DatePicker className="form-control" selected={fromDt} onChange={(date: Date) => setFromDt(date)} />
              </label>
              <button className="btn btn-primary" id="btn-chart" onClick={handleChartClick}>
                Chart
              </button>
            </div>
          </>
        )}
        <div style={{ alignItems: "right", textAlign: "right" }}>
          <button className="btn btn-secondary" onClick={() => setIsSidebarOpen(!isSidebarOpen)} style={{ marginBottom: "10px" }}>
            {isSidebarOpen ? "<<" : ">>"}
          </button>
        </div>
      </div>

      <div style={{ width: isSidebarOpen ? "85%" : "97%", transition: "width 0.3s", padding: "20px" }}>
        {chartData && (
          <Chart
            type="line"
            data={chartData}
            options={{
              scales: {
                x: {
                  type: "time",
                  title: {
                    display: true,
                    text: "Date",
                  },
                  time: {
                    unit: "day",
                    tooltipFormat: "d MMM yy",
                    displayFormats: {
                      day: "d MMM yy",
                    },
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: "% Change",
                  },
                  beginAtZero: false,
                },
                y1: {
                  type: "linear",
                  position: "right", // Secondary y-axis on the right
                  ticks: {
                    callback: (value: any) => `${value}`, // Format values as percentages
                  },
                  grid: {
                    drawOnChartArea: false, // Don't draw grid lines for the secondary y-axis
                  },
                },
              },
              plugins: {
                annotation: {
                  annotations: {
                    zeroLine: {
                      type: "line",
                      yMin: 0,
                      yMax: 0,
                      borderColor: "red",
                      borderWidth: 2,
                      label: {
                        content: "Zero Line",
                        position: "center",
                      },
                    },
                  },
                },
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EconomicCharts;
