import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export interface BarChartPnlElement {
  symbol: string;
  pnl: number;
}

const BarChart: React.FC<{ data: BarChartPnlElement[] }> = ({ data }) => {
  const [chartData, setChartData] = useState({
    labels: [] as string[],
    datasets: [] as { label: string; data: number[]; backgroundColor: string[] }[],
  });

  useEffect(() => {
    const processData = (data: BarChartPnlElement[]) => {
      const labels = data.map((item) => item.symbol);
      const pnlData = data.map((item) => item.pnl);
      const backgroundColors = pnlData.map((pnl) => (pnl >= 0 ? "rgba(75, 192, 192, 0.6)" : "rgba(255, 99, 132, 0.6)"));

      setChartData({
        labels: labels,
        datasets: [
          {
            label: "PnL",
            data: pnlData,
            backgroundColor: backgroundColors, // Set background color based on pnl value
          },
        ],
      });
    };

    processData(data);
  }, [data]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: "Symbol",
        },
      },
      y: {
        title: {
          display: true,
          text: "P&L",
        },
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend if not needed
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChart;
