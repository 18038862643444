import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import DatePicker from "react-datepicker";
import ClipboardJS from "clipboard";
import "react-datepicker/dist/react-datepicker.css";
import { useAtom } from "jotai";
import {
  customerIdAtom,
  tokenAtom,
  selectedPortfolioIdAtom,
  selectedDateAtom,
  selectedCurrencyAtom,
  clickedSymbolAtom,
  clickedPortfolioIdAtom,
  useCanadianCostBasisAtom,
  useSbiTtRatesAtom,
} from "../state/atoms";
import "../styles/Portfolio.css";
import StockDetailsModal from "./StockDetailsModal";

interface PortfolioOption {
  portfolio_id: number;
  portfolio_name: string;
}

interface Position {
  portfolio_id: number;
  portfolio_name: string;
  currency: string;
  symbol: string;
  name?: string;
  quantity: number;
  avg_price?: number;
  avg_price_orij: number;
  total_cost?: number;
  last_price: number;
  current_value: number;
  day_change?: number;
  day_change_pct?: number;
  unrlz_pnl?: number;
  unrlz_pnl_pct?: number;
  avg_hld_days?: number;
  cagr?: number;
  exch_pair_used: string;
  exch_rate: number;
  exch_rate_dt: string;
  reporting_currency: string;
  total_cost_rc?: number;
  current_value_rc: number;
  unrlz_pnl_rc?: number;
}

const Portfolio: React.FC = () => {
  const [customerId] = useAtom(customerIdAtom);
  const [token] = useAtom(tokenAtom);
  const [selectedPortfolioId, setSelectedPortfolioId] = useAtom(selectedPortfolioIdAtom);
  const [date, setDate] = useAtom(selectedDateAtom);
  const [currency, setCurrency] = useAtom(selectedCurrencyAtom);
  const [portfolios, setPortfolios] = useState<PortfolioOption[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [modalShow, setModalShow] = useState(false);
  const [clickedSymbol, setClickedSymbol] = useAtom(clickedSymbolAtom);
  const [clickedPortfolioId, setClickedPortfolioId] = useAtom(clickedPortfolioIdAtom);
  const tableRef = useRef<HTMLTableElement>(null);
  const [useCanadianCostBasis, setUseCanadianCostBasis] = useAtom(useCanadianCostBasisAtom);
  const [useSbiTtRates, setUseSbiTtRates] = useAtom(useSbiTtRatesAtom);
  const [sortColumn, setSortColumn] = useState<string>("unrlz_pnl desc");

  const handleToggle = () => {
    setUseCanadianCostBasis(!useCanadianCostBasis);
  };

  const handleSbiTtToggle = () => {
    setUseSbiTtRates(!useSbiTtRates);
  };

  const handleImageClick = (symbol: string, portfolioId: number) => {
    setClickedSymbol(symbol);
    setClickedPortfolioId(portfolioId);
    setModalShow(true);
  };

  useEffect(() => {
    const fetchPortfolios = async () => {
      const config = { headers: { Authorization: `Bearer ${token.accessToken}` } };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolios`, config);
      setPortfolios([{ portfolio_id: 0, portfolio_name: "All" }, ...response.data]);
    };

    if (token) fetchPortfolios();
  }, [token, customerId]);

  useEffect(() => {
    const fetchPositions = async () => {
      const config = {
        headers: { Authorization: `Bearer ${token.accessToken}` },
        params: {
          reporting_currency: currency,
          dt: date.toISOString().split("T")[0],
          use_canadian_cost_basis: useCanadianCostBasis,
          use_sbi_tt_rates: useSbiTtRates,
        },
      };
      const url = `${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolio/${selectedPortfolioId}/positions_by_date`;
      const response = await axios.get(url, config);
      setPositions(response.data);
    };

    if (token && selectedPortfolioId !== undefined) fetchPositions();
  }, [selectedPortfolioId, date, currency, useCanadianCostBasis, useSbiTtRates, token, customerId]);

  const handleCopyClick = () => {
    if (tableRef.current) {
      const clipboard = new ClipboardJS("#copyButton", {
        target: () => tableRef.current as HTMLElement,
      });

      clipboard.on("success", (e) => {
        console.log("Table copied successfully!");
        e.clearSelection();
      });

      clipboard.on("error", (e) => {
        console.error("Failed to copy table:", e);
      });

      // clipboard.onClick({ currentTarget: document.getElementById("copyButton") });
    }
  };

  const greatest = (a: number, b: number) => {
    return a > b ? a : b;
  };

  const containesAnyWord = (text: string, phrase: string) => {
    if (phrase.trim() === "") return true;
    const negate = phrase.includes("!");
    if (negate) {
      phrase = phrase.replaceAll("!", "");
    }
    if (phrase.trim() === "") return true;
    const words: string[] = phrase.toLowerCase().replaceAll(",", " ").trim().split(" ");
    return negate
      ? words.every((word) => word.trim() !== "" && text.toLowerCase().startsWith(word) === false)
      : words.some((word) => word.trim() !== "" && text.toLowerCase().startsWith(word));
  };

  const filteredPositions = positions.filter((position) => containesAnyWord(position.symbol.toLowerCase(), filterText.toLowerCase()));
  // Sort filteredPositions based on sortColumn
  filteredPositions.sort((a, b) => {
    const [column, order] = sortColumn.split(" ");
    const aVal = a[column as keyof Position];
    const bVal = b[column as keyof Position];
    if (order === "desc") {
      return (aVal || 0) < (bVal || 0) ? 1 : -1;
    } else {
      return (aVal || 0) > (bVal || 0) ? 1 : -1;
    }
  });

  // Calculate summary values
  const totalCost = filteredPositions.reduce((sum, position) => sum + (position.total_cost_rc || 0), 0);
  const currentValue = filteredPositions.reduce((sum, position) => sum + position.current_value_rc, 0);
  const dayPnL = filteredPositions.reduce((sum, position) => sum + (position.day_change || 0) * position.exch_rate, 0);
  const unrealizedPnL = currentValue - totalCost;
  const unrealizedPnLPercent = totalCost !== 0 ? (unrealizedPnL / totalCost) * 100 : 0;
  const isOneCurrency = filteredPositions.every((position) => position.currency === currency);

  const cagr_sum = filteredPositions.reduce(
    (sum, position) => sum + (position.cagr || 0) * greatest(position.avg_hld_days || 0, 1) * (position.total_cost || 0.001),
    0
  );
  const weights_sum = filteredPositions.reduce(
    (sum, position) => sum + ((position.avg_hld_days || 1) > 0 ? position.avg_hld_days || 1 : 1) * (position.total_cost || 0.001),
    0
  );
  const cagr = weights_sum > 0 ? cagr_sum / weights_sum : 0;

  return (
    <div className="container">
      <div className="title-bar">
        <h3>Open Positions</h3>
      </div>
      <div className="filter-bar bg-secondary p-2">
        <div className="filter-item align-items-center col-lg-6">
          <label className="font-bold">Portfolio: </label>
          <select className="form-control col-lg-6 col-sm-12" value={selectedPortfolioId} onChange={(e) => setSelectedPortfolioId(Number(e.target.value))}>
            {portfolios.map((portfolio) => (
              <option key={portfolio.portfolio_id} value={portfolio.portfolio_id}>
                {portfolio.portfolio_name}
              </option>
            ))}
          </select>
        </div>
        <div className="filter-item align-items-center col-lg-2">
          <label>Date: </label>
          <DatePicker className="form-control" selected={date} onChange={(date: Date) => setDate(date)} />
        </div>
        <div className="filter-item align-items-center col-lg-2">
          <label>Currency: </label>
          <select className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="USD">USD</option>
            <option value="CAD">CAD</option>
            <option value="INR">INR</option>
          </select>
        </div>
      </div>
      <div className="filter-bar bg-secondary p-2">
        <Form.Check type="switch" id="canadian-cost-basis-switch" label="Canadian Cost Basis" checked={useCanadianCostBasis} onChange={handleToggle} />
        <Form.Check type="switch" id="sbi-tt-switch" label="SBI TT Rates" checked={useSbiTtRates} onChange={handleSbiTtToggle} />
        <div className="filter-item align-items-center col-lg-2">
          <label>Sort:</label>
          <select className="form-control" value={sortColumn} onChange={(e) => setSortColumn(e.target.value)}>
            <option value="unrlz_pnl desc">Unrealized P&L (Desc)</option>
            <option value="symbol desc">Symbol (Desc)</option>
            <option value="unrlz_pnl_pct desc">Unrealized P&L% (Desc)</option>
            <option value="day_change desc">Day Change (Desc)</option>
            <option value="day_change_pct desc">Day Change% (Desc)</option>
            <option value="total_cost desc">Cost (Desc)</option>
            <option value="symbol">Symbol (Asc)</option>
            <option value="unrlz_pnl">Unrealized P&L (Asc)</option>
            <option value="unrlz_pnl_pct">Unrealized P&L% (Asc)</option>
            <option value="day_change">Day Change (Asc)</option>
            <option value="day_change_pct">Day Change% (Asc)</option>
            <option value="total_cost">Cost (Asc)</option>
          </select>
        </div>
      </div>
      <div className="quick-filter-box bg-secondary p-2">
        <input type="text" className="form-control" value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Filter by symbol..." />
      </div>

      {/* Summary Section */}
      <div className="summary-container">
        <div className="summary-box">
          <div className="summary-title">Total Cost</div>
          <div className="summary-number">{totalCost.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Current Value</div>
          <div className="summary-number">{currentValue.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Unrealized P&L</div>
          <div className={(unrealizedPnL || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
            {unrealizedPnL.toLocaleString(undefined, { style: "currency", currency: currency })}
            <span className="small-text">
              <br />
              {unrealizedPnLPercent.toFixed(2)}% &nbsp;&nbsp;&nbsp;{cagr.toFixed(2)}%/yr
            </span>
          </div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Day P&L</div>
          <div className={(dayPnL || 0) > 0 ? "summary-number green-cell" : "summary-number red-cell"}>
            {dayPnL.toLocaleString(undefined, { style: "currency", currency: currency })}
          </div>
        </div>
      </div>

      <div className="right-al">
        Showing {filteredPositions.length} of {positions.length} positions
        <button className="btn" id="copyButton" onClick={handleCopyClick}>
          📋 Copy
        </button>
      </div>
      <table className="table table-striped mt-2" ref={tableRef}>
        <thead>
          <tr>
            <th className="numeric-cell">#</th>
            {selectedPortfolioId === 0 ? <th>Portfolio</th> : null}
            <th>Symbol</th>
            <th>Chart</th>
            <th className="numeric-cell">Unrealized P&L</th>
            <th className="numeric-cell">Day P&L</th>
            <th className="numeric-cell">Quantity</th>
            <th className="numeric-cell">Avg Price</th>
            <th className="numeric-cell">Total Cost</th>
            <th className="numeric-cell">Last Price</th>
            <th className="numeric-cell">Current Value</th>
            {!isOneCurrency ? <th className="numeric-cell rc-cell left-border">Total Cost ({currency})</th> : null}
            {!isOneCurrency ? <th className="numeric-cell rc-cell">Current Value ({currency})</th> : null}
            {!isOneCurrency ? <th className="numeric-cell rc-cell">Unrealized P&L ({currency})</th> : null}
          </tr>
        </thead>
        <tbody>
          {filteredPositions.map((position, index) => (
            // Use US locale for currency formatting
            // Use US locale if currency is not INR, for total_cost field
            // For unrealized pnl column use grren-cell class is pnl is positive
            <tr key={index}>
              <td className="numeric-cell">{index + 1}</td>
              {selectedPortfolioId === 0 ? <td>{position.portfolio_name}</td> : null}
              <td>
                {position.symbol}
                {position.name ? (
                  <>
                    <br />
                    <span className="small-text">{position.name}</span>
                  </>
                ) : null}
              </td>
              <td>
                <button className="btn btn-link" onClick={() => handleImageClick(position.symbol, position.portfolio_id)}>
                  <img src={`https://rjore.xyz/portfolio-mini-charts/${position.symbol}-${position.portfolio_id}.png`} alt="N/A" style={{ maxHeight: 75 }} />
                </button>
              </td>
              <td className={(position.unrlz_pnl || 0) > 0 ? "green-cell" : "red-cell"}>
                {position.unrlz_pnl?.toLocaleString(undefined, { style: "currency", currency: position.currency })}
                <br />
                <span className="small-text">{position.unrlz_pnl_pct?.toLocaleString()}%</span>
                <br />
                {position.cagr ? <span className="small-text">{position.cagr?.toFixed(2)}%/yr</span> : null}
              </td>
              <td className={(position.day_change_pct || 0) > 0 ? "green-cell" : "red-cell"}>
                {position.day_change?.toLocaleString(undefined, { style: "currency", currency: position.currency })}
                <br />
                <span className="small-text">({position.day_change_pct?.toLocaleString()}%)</span>
              </td>
              <td className="numeric-cell">{position.quantity.toLocaleString()}</td>
              <td className={(Math.abs((position.avg_price || 0) - position.avg_price_orij) > 1e-2 ? "adj-price-cell " : "") + "numeric-cell"}>
                {position.avg_price?.toLocaleString(undefined, { style: "currency", currency: position.currency })}
              </td>
              <td className="numeric-cell">{position.total_cost?.toLocaleString(undefined, { style: "currency", currency: position.currency })}</td>
              <td className="numeric-cell">{position.last_price.toLocaleString(undefined, { style: "currency", currency: position.currency })}</td>
              <td className="numeric-cell">{position.current_value.toLocaleString(undefined, { style: "currency", currency: position.currency })}</td>
              {!isOneCurrency ? (
                <td className="numeric-cell rc-cell left-border">
                  {position.total_cost_rc?.toLocaleString(undefined, { style: "currency", currency: currency })}
                </td>
              ) : null}
              {!isOneCurrency ? (
                <td className="numeric-cell rc-cell">{position.current_value_rc.toLocaleString(undefined, { style: "currency", currency: currency })}</td>
              ) : null}
              {!isOneCurrency ? (
                <td className={(position.unrlz_pnl_rc || 0) > 0 ? "green-cell rc-cell" : "red-cell rc-cell"}>
                  {position.unrlz_pnl_rc?.toLocaleString(undefined, { style: "currency", currency: currency })}
                </td>
              ) : null}
            </tr>
          ))}
        </tbody>
      </table>

      <StockDetailsModal show={modalShow} onHide={() => setModalShow(false)} symbol={clickedSymbol} portfolioId={clickedPortfolioId} customerId="1" />
    </div>
  );
};

export default Portfolio;
