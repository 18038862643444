// src/components/Login.tsx
import React, { useState } from "react";
import { useSetAtom } from "jotai";
import { tokenAtom, customerIdAtom } from "../state/atoms";
import { login } from "../api/services";

export const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const setToken = useSetAtom(tokenAtom);
  const setCustomerId = useSetAtom(customerIdAtom);

  const handleLogin = async () => {
    try {
      const data = await login(username, password);
      setToken({ accessToken: data.access_token, refreshToken: data.refresh_token });
      readCustomerId(data.access_token);
      localStorage.setItem("tokens", JSON.stringify({ accessToken: data.access_token, refreshToken: data.refresh_token }));
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const readCustomerId = (token: string) => {
    // Read cid from access token print
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    const payload = JSON.parse(jsonPayload);

    if (payload?.app_metadata?.cid) {
      setCustomerId(payload.app_metadata.cid);
    }
  };

  return (
    // Add div taking up full visible space
    <div className="d-flex full-height justify-content-center align-items-center">
      <div>
        <div className="flex align-items-right">
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
        </div>
        <div className="flex align-items-right">
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
        </div>
        <div className="flex justify-content-right">
          <button onClick={handleLogin}>Login</button>
        </div>
      </div>
    </div>
  );
};
