import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import ClipboardJS from "clipboard";
import "react-datepicker/dist/react-datepicker.css";
import { useAtom } from "jotai";
import { customerIdAtom, tokenAtom, selectedFromDateAtom, selectedToDateAtom, selectedCurrencyAtom, useSbiTtRatesAtom } from "../state/atoms";
import { Form } from "react-bootstrap";

interface IndiaDividend {
  bank_name: string;
  acc_no: string;
  currency: string;
  dt: string;
  dividend: number;
  tds: number;
  payout: number;
  description: string;
  exch_pair: string;
  exch_rate?: number;
  reporting_currency: string;
  dividend_rc?: number;
  tds_rc?: number;
}

const IndiaDividends: React.FC = () => {
  const [customerId] = useAtom(customerIdAtom);
  const [token] = useAtom(tokenAtom);
  const [fromDate, setFromDate] = useAtom(selectedFromDateAtom);
  const [toDate, setToDate] = useAtom(selectedToDateAtom);
  const [currency, setCurrency] = useAtom(selectedCurrencyAtom);
  const [useSbiTtRates, setUseSbiTtRates] = useAtom(useSbiTtRatesAtom);
  const [dividends, setDividends] = useState<IndiaDividend[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const tableRef = useRef<HTMLTableElement>(null);

  const handleSbiTtToggle = () => {
    setUseSbiTtRates(!useSbiTtRates);
  };

  useEffect(() => {
    const fetchBankInterests = async () => {
      const config = {
        headers: { Authorization: `Bearer ${token.accessToken}` },
        params: {
          reporting_currency: currency,
          from_dt: fromDate.toISOString().split("T")[0],
          to_dt: toDate.toISOString().split("T")[0],
          use_sbi_tt_rates: useSbiTtRates,
        },
      };
      const url = `${process.env.REACT_APP_API_URL}/v1/${customerId}/portfolio/0/india_dividends_by_date`;
      const response = await axios.get(url, config);
      setDividends(response.data);
    };

    if (token !== undefined) fetchBankInterests();
  }, [fromDate, toDate, currency, useSbiTtRates, token, customerId]);

  const handleCopyClick = () => {
    if (tableRef.current) {
      const clipboard = new ClipboardJS("#copyButton", {
        target: () => tableRef.current as HTMLElement,
      });

      clipboard.on("success", (e) => {
        console.log("Table copied successfully!");
        e.clearSelection();
      });

      clipboard.on("error", (e) => {
        console.error("Failed to copy table:", e);
      });

      // clipboard.onClick({ currentTarget: document.getElementById("copyButton") });
    }
  };

  const filteredRecords = dividends.filter(
    (dividend) =>
      (dividend.description || "").toLowerCase().includes(filterText.toLowerCase().trim()) ||
      (dividend.bank_name || "").toLowerCase().includes(filterText.toLowerCase().trim()) ||
      (dividend.acc_no || "").toLowerCase().includes(filterText.toLowerCase().trim())
  );
  const total_interest = filteredRecords.reduce((sum, position) => sum + (position.dividend_rc || 0), 0);
  const total_interest_tax_witheld = filteredRecords.reduce((sum, position) => sum + (position.tds_rc || 0), 0);

  return (
    <div className="container">
      <div className="title-bar">
        <h3>India Dividends</h3>
      </div>
      <div className="filter-bar bg-secondary p-2">
        <div className="filter-item align-items-center col-lg-4">
          <label>Date Range: </label>
          <DatePicker className="form-control" selected={fromDate} onChange={(date: Date) => setFromDate(date)} /> to{" "}
          <DatePicker className="form-control" selected={toDate} onChange={(date: Date) => setToDate(date)} />
        </div>
        <div className="filter-item align-items-center col-lg-4">
          <label>Currency: </label>
          <select className="form-control" value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="USD">USD</option>
            <option value="CAD">CAD</option>
            <option value="INR">INR</option>
          </select>
        </div>
      </div>
      <div className="filter-bar bg-secondary p-2">
        <Form.Check type="switch" id="sbi-tt-switch" label="SBI TT Rates" checked={useSbiTtRates} onChange={handleSbiTtToggle} />
      </div>

      <div className="quick-filter-box bg-secondary p-2">
        <input type="text" className="form-control" value={filterText} onChange={(e) => setFilterText(e.target.value)} placeholder="Filter text..." />
      </div>

      {/* Summary Section */}
      <div className="summary-container">
        <div className="summary-box">
          <div className="summary-title">Dividend ({currency})</div>
          <div className="summary-number">{total_interest.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
        </div>
        <div className="summary-box">
          <div className="summary-title">Tax on Dividend ({currency})</div>
          <div className="summary-number">{total_interest_tax_witheld.toLocaleString(undefined, { style: "currency", currency: currency })}</div>
        </div>
      </div>

      <div className="right-al">
        <button className="btn" id="copyButton" onClick={handleCopyClick}>
          📋 Copy
        </button>
      </div>

      <table className="table table-striped" ref={tableRef}>
        <thead>
          <tr>
            <th className="numeric-cell">#</th>
            <th>Bank</th>
            <th>Acc #</th>
            <th>Currency</th>
            <th className="numeric-cell">Date</th>
            <th>Description</th>
            <th className="numeric-cell">Dividend</th>
            <th className="numeric-cell">TDS</th>
            <th className="numeric-cell">Exch Rate</th>
            <th className="numeric-cell">Dividend ({currency})</th>
            <th className="numeric-cell">TDS ({currency})</th>
          </tr>
        </thead>
        <tbody>
          {filteredRecords.map((dividend, index) => (
            <tr key={index}>
              <td className="numeric-cell">{index + 1}</td>
              <td>{dividend.bank_name}</td>
              <td>{dividend.acc_no}</td>
              <td>{dividend.currency}</td>
              <td className="numeric-cell">{dividend.dt}</td>
              <td>{dividend.description}</td>
              <td className="numeric-cell">{dividend.dividend.toLocaleString(undefined, { style: "currency", currency: dividend.currency })}</td>
              <td className="numeric-cell">{dividend.tds.toLocaleString(undefined, { style: "currency", currency: dividend.currency })}</td>
              <td className="numeric-cell">{dividend.exch_rate?.toFixed(6)}</td>
              <td className="numeric-cell">{dividend.dividend_rc?.toLocaleString(undefined, { style: "currency", currency: currency })}</td>
              <td className="numeric-cell">{dividend.tds_rc?.toLocaleString(undefined, { style: "currency", currency: currency })}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default IndiaDividends;
